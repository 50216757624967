import Container from '@mui/material/Container';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addExternalUser } from '../../../actions/farmsAccessActions';
import {
  ArchiveFarmModal,
  FarmMillAssignmentsTable,
  MillAssignmentModal,
  MillSelect,
  ReactivateFarmModal,
} from '../../../components/farm-mill-assignments';
import { FarmsSelect } from '../../../components/farms';
import LoadScreen from '../../../components/load-screen';
import { urls } from '../../../constants';
import { userSelector } from '../../../reducers/selectors';
import axios from '../../../services/axios';
import { containerPadding } from '../../../styles/theme/shared';

const FarmMillsAssignmentsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector(userSelector);

  const [fetching, setFetching] = useState(false);
  const [selectedMill, setSelectedMill] = useState(null);
  const [selectedFarms, setSelectedFarms] = useState([]);
  const [millFarms, setMillFarms] = useState([]);
  const [selectedMillFarm, setSelectedMillFarm] = useState(null);

  const [archiveModalOpen, toggleArchiveModal] = useState(false);
  const [reactivateModalOpen, toggleReactivateModal] = useState(false);
  const [millModalOpen, toggleMillModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [externalUserLoading, setExternalUserLoading] = useState(false);

  const onMillSelect = async (value) => {
    setSelectedMill(value);
    try {
      setFetching(true);
      const res =
        value?.mill_id === 'ARCHIVED'
          ? await axios.get(urls.millAssignments.archivedFarms)
          : await axios.post(urls.millAssignments.millFarms, { mill_id: value.mill_id });
      if (res?.data?.farms) {
        setMillFarms(res.data.farms || []);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const onChangeMillClick = async (row) => {
    if (row) {
      const res = await axios.post(urls.millAssignments.farmMillAssociation, {
        mill_id: row?.mill?.mill_id,
        farm_id: row?.farm_id,
      });
      if (res && res?.data) {
        setSelectedMillFarm({ ...row, ...res?.data });
        toggleMillModal(true);
      }
    }
  };

  const onMillFarmUpdate = async (millId, users) => {
    if (selectedMillFarm) {
      try {
        setLoading(true);
        const body = {
          users,
          mill_id: millId,
          farm_id: selectedMillFarm.farm_id,
          company_id: currentUser?.company?.id,
        };
        await axios.patch(urls.millAssignments.farmMillAssociation, body);
        const res = await axios.post(urls.millAssignments.millFarms, { mill_id: selectedMill?.mill_id });
        if (res?.data?.farms) {
          setMillFarms(res.data.farms || []);
        }
        toggleMillModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const onArchiveClick = async (row) => {
    if (row) {
      const res = await axios.post(urls.millAssignments.farmMillAssociation, {
        mill_id: row?.mill?.mill_id,
        farm_id: row?.farm_id,
      });
      if (res && res?.data) {
        setSelectedMillFarm({ ...row, ...res?.data });
        toggleArchiveModal(true);
      }
    }
  };

  const onArchive = async (users) => {
    if (selectedMillFarm) {
      try {
        setLoading(true);
        const body = {
          users,
          farm_id: selectedMillFarm.farm_id,
        };
        await axios.post(urls.millAssignments.archiveFarm, body);
        const res = await axios.post(urls.millAssignments.millFarms, { mill_id: selectedMillFarm?.mill?.mill_id });
        if (res?.data?.farms) {
          setMillFarms(res.data.farms || []);
        }
        toggleArchiveModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const onReactivateClick = async (row) => {
    if (row) {
      const res = await axios.post(urls.millAssignments.farmMillAssociation, {
        mill_id: row?.mill_id,
        farm_id: row?.farm_id,
      });
      if (res && res?.data) {
        setSelectedMillFarm({ ...row, ...res?.data });
        toggleReactivateModal(true);
      }
    }
  };

  const onReactivate = async (users) => {
    if (selectedMillFarm) {
      try {
        setLoading(true);
        const body = {
          users,
          farm_id: selectedMillFarm.farm_id,
        };
        await axios.patch(urls.millAssignments.reactivateFarm, body);
        const res = await axios.get(urls.millAssignments.archivedFarms);
        if (res?.data?.farms) {
          setMillFarms(res.data.farms || []);
        }
        toggleReactivateModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const onAddExternalUser = async (date, email) => {
    if (selectedMillFarm) {
      try {
        setExternalUserLoading(true);
        const userObj = {
          farm_ids: [selectedMillFarm?.farm_id],
          email: email?.trim(),
          expiration_date: format(date, 'yyyy-MM-dd'),
        };
        await dispatch(addExternalUser(userObj));
        const res = await axios.post(urls.millAssignments.farmMillAssociation, {
          mill_id: selectedMillFarm?.mill?.mill_id,
          farm_id: selectedMillFarm?.farm_id,
        });
        if (res && res?.data) {
          setSelectedMillFarm({ ...selectedMillFarm, external_users: res?.data?.external_users || [] });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setExternalUserLoading(false);
      }
    }
  };

  const list = useMemo(() => {
    return millFarms
      .filter((farm) => !selectedFarms?.length || selectedFarms?.find((f) => f.farm_id === farm.farm_id))
      .map((farm) => ({ ...farm, mill: selectedMill }));
  }, [selectedMill, millFarms, selectedFarms]);

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      {fetching && <LoadScreen />}
      <h2>{t('farmMillAssignments.title')}</h2>

      <MillSelect
        millsList={[
          ...currentUser.company_mills,
          {
            id: 'ARCHIVED',
            name: 'Archived',
            mill_id: 'ARCHIVED',
          },
        ]}
        onSelect={onMillSelect}
        selected={selectedMill}
      />

      <FarmsSelect farmsList={millFarms} onSelectChange={setSelectedFarms} pickedFarms={selectedFarms} />

      {!!selectedMill && !!millFarms?.length && (
        <FarmMillAssignmentsTable
          data={list}
          onArchiveClick={onArchiveClick}
          onChangeMillClick={onChangeMillClick}
          onReactivateClick={onReactivateClick}
        />
      )}

      {!!selectedMillFarm && (
        <MillAssignmentModal
          data={selectedMillFarm}
          externalUserLoading={externalUserLoading}
          loading={loading}
          mills={currentUser.company_mills}
          onAddExternalUser={onAddExternalUser}
          onClose={() => toggleMillModal(false)}
          onSubmit={onMillFarmUpdate}
          open={millModalOpen}
        />
      )}

      {!!selectedMillFarm && (
        <ArchiveFarmModal
          data={selectedMillFarm}
          loading={loading}
          mills={currentUser.company_mills}
          onClose={() => toggleArchiveModal(false)}
          onSubmit={onArchive}
          open={archiveModalOpen}
        />
      )}

      {!!selectedMillFarm && (
        <ReactivateFarmModal
          data={selectedMillFarm}
          loading={loading}
          mills={currentUser.company_mills}
          onClose={() => toggleReactivateModal(false)}
          onSubmit={onReactivate}
          open={reactivateModalOpen}
        />
      )}
    </Container>
  );
};

export default FarmMillsAssignmentsPage;
