// import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';

// import amtsLogo from '../../../assets/amts-logo.png';
import { containerPadding } from '../../../styles/theme/shared';

const DashboardPage = () => (
  <Container maxWidth={false} sx={{ p: containerPadding }}>
    <h2>Dashboard</h2>
    {/*<Box*/}
    {/*  component='div'*/}
    {/*  sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 12 }}*/}
    {/*>*/}
    {/*  <img alt='amts-logo' src={amtsLogo} />*/}
    {/*</Box>*/}
  </Container>
);

export default DashboardPage;
