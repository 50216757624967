import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../shared';

const NotesModal = (props) => {
  const { open, onClose, mobileView, notes, rationInfo } = props;

  const { t } = useTranslation();

  return (
    <Modal
      closeText={t('close')}
      maxWidth={'md'}
      onClose={onClose}
      open={open}
      title={t('rations.notesWithTitle', { info: rationInfo })}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: mobileView ? 'column' : 'row',
          marginBlock: '16px',
          whiteSpace: 'pre-line',
        }}
      >
        {notes}
      </Box>
    </Modal>
  );
};

export default NotesModal;
