import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import MoreIcon from '@mui/icons-material/MoreVert';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '../shared';

const FarmMillAssignmentsTable = ({ data = [], onChangeMillClick, onArchiveClick, onReactivateClick }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    event.stopPropagation();
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      title: t('farmMillAssignments.farm'),
      field: 'farm_name',
      sort: false,
      formatter: (farmName, row) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>{farmName}</Typography>

          <IconButton
            aria-controls={`${row.farm_id}_${row.mill_id || row.mill.mill_id}_farm`}
            aria-haspopup='true'
            aria-label='customer actions'
            color='inherit'
            id={`${row.farm_id}_${row.mill_id || row.mill.mill_id}_farm`}
            onClick={handleMenu}
          >
            <MoreIcon color='secondary' />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            elevation={1}
            id={`${row.farm_id}_${row.mill_id || row.mill.mill_id}_farm`}
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl) && anchorEl.id === `${row.farm_id}_${row.mill_id || row.mill.mill_id}_farm`}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {row?.mill?.mill_id !== 'ARCHIVED' && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                  onChangeMillClick(row);
                }}
              >
                <EditIcon color='secondary' fontSize='small' sx={{ mr: 1 }} />
                {t('farmMillAssignments.changeMill')}
              </MenuItem>
            )}
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
                if (row?.mill?.id === 'ARCHIVED') {
                  onReactivateClick(row);
                } else {
                  onArchiveClick(row);
                }
              }}
            >
              {row?.mill?.id === 'ARCHIVED' ? (
                <UnarchiveIcon color='secondary' fontSize='small' sx={{ mr: 1 }} />
              ) : (
                <ArchiveIcon color='secondary' fontSize='small' sx={{ mr: 1 }} />
              )}

              {row?.mill?.id === 'ARCHIVED'
                ? t('farmMillAssignments.reactivateFarm')
                : t('farmMillAssignments.archiveFarm')}
            </MenuItem>
          </Menu>
        </Box>
      ),
    },
    {
      title: t('farmMillAssignments.millAssignment'),
      field: 'mill',
      sort: false,
      formatter: (mill, row) => (
        <Typography>{mill.id === 'ARCHIVED' ? `${row.mill_name || ''} (Archived)` : mill?.name || ''}</Typography>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      // keyField='id'
      // pagination
      rows={data}
      stickyHeader
      // sx={{ maxHeight: { md: 'calc(100vh - 330px)', xs: 'calc(100vh - 140px)' } }}
    />
  );
};

export default FarmMillAssignmentsTable;
