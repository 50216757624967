const routes = {
  LOGIN: '/login',
  HOME: '/',
  DASHBOARD: '/dashboard',
  FARM_ACCESS: '/farm-access',
  ACTIVITY_LOG: '/activity-log',
  SETTINGS: '/settings',
  FARMS: '/farms',
  RATIONS: '/rations',
  USER_PERMISSIONS: '/user-permissions',
  TRANSFER_MONITOR: '/transfer-monitor',
  MIXES: '/mixes',
  FEEDS: '/feeds',
  COMPARE_RATIONS: '/compare-rations',
  COMPARE_FEEDS: '/compare-feeds',
  BCS: '/body-condition-score',
  BCS_NEW_SCORE: '/body-condition-score/new',
  BCS_RESULTS: '/body-condition-score/results',
  PSC: '/particle-size-calculator',
  PSC_SINGLE_SAMPLE_FEED: '/particle-size-calculator/single-sample-feed',
  PSC_SINGLE_SAMPLE_TMR: '/particle-size-calculator/single-sample-tmr',
  PSC_FEED_HISTORY: '/particle-size-calculator/feed-history',
  PSC_TMR_HISTORY: '/particle-size-calculator/tmr-history',
  FARM_VISIT: '/farm-visit',
  FARM_VISIT_NEW: '/farm-visit/new',
  FARM_VISIT_RESULTS: '/farm-visit/results',
  ADMIN_FARM_VISIT: '/admin-farm-visit',
  ADMIN_FARM_VISIT_NEW: '/admin-farm-visit/new',
  ADMIN_FARM_VISIT_LIST: '/admin-farm-visit/list',
  FARM_MILL_ASSIGNMENTS: '/farm-mill-assignments',
};

export default routes;
