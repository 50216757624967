/* eslint-disable max-len */
import Box from '@mui/material/Box';
import React from 'react';

import { useSettings } from '../../hooks';
import { Table } from '../shared';

const ComparisonsTable = (props) => {
  const { data, unit, isMobile } = props;

  const { formatDate, formatWeight } = useSettings();

  if (!data || !data?.length) {
    return null;
  }

  const getFeedColumns = () => {
    const feedRow = new Set();
    data.forEach((q) => {
      q.feeds.forEach((feed) => feedRow.add(feed.feed_name));
    });

    return Array.from(feedRow);
  };

  const getColumns = () => {
    const valuesColumns = [
      {
        title: 'Feed',
        field: 'feed_name',
        sort: true,
        headerStyles: { minWidth: '200px' },
      },
    ];
    data.forEach((el, idx) => {
      valuesColumns.push({
        title: el.RecipeName,
        field: `${unit}_${idx}`,
        sort: true,
        formatter: (value, row) => (value ? formatWeight(value, row.feed_type, '(lbs)') : '---'),
        headerFormatter: () => (
          <Box component='div' sx={{ minWidth: '250px' }}>
            <Box component='div'>{el.FarmName}</Box>
            <Box component='div'>{el.RecipeName}</Box>
            <Box component='div'>{formatDate(el.OADate, true)}</Box>
          </Box>
        ),
        exportHeaderFormatter: () => `${el.FarmName} – ${el.RecipeName} - ${el.OADate}`,
      });
    });
    return valuesColumns;
  };

  const getRows = () => {
    const newData = [];
    const feedColumns = getFeedColumns();

    feedColumns.forEach((feedCol) => {
      const feedObj = { feed_name: feedCol };
      data.forEach((q, index) => {
        const feedInfo = q?.feeds?.find((feed) => feed.feed_name === feedCol);
        if (feedInfo) {
          feedObj[`DM_amount_${index}`] = feedInfo.DM_amount;
          feedObj[`AF_amount_${index}`] = feedInfo.AF_amount;
          feedObj.feed_type = feedInfo && feedInfo.feed_type ? feedInfo.feed_type : '';
        }
      });
      newData.push(feedObj);
    });

    return newData;
  };

  const columns = getColumns();
  const rows = getRows();

  return (
    <Table
      columns={columns}
      keyField='feed_name'
      rows={rows}
      stickyColumn
      stickyHeader
      sx={{ maxHeight: { md: 'calc(100vh - 290px)', xs: 'calc(100vh - 140px)' } }}
      toolbar
    />
  );
};

export default ComparisonsTable;
