import FarmMillAssignmentsIcon from '@mui/icons-material/AssignmentInd';
import FarmVisitIcon from '@mui/icons-material/DirectionsWalk';
import AccessIcon from '@mui/icons-material/ManageAccounts';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/shared/button';
import routes from '../../../constants/routes';
import { containerPadding } from '../../../styles/theme/shared';

const AdminDashboardPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const links = useMemo(
    () => [
      {
        label: 'dashboard.manageFarmAccess',
        route: routes.FARM_ACCESS,
        icon: <AccessIcon fontSize='large' />,
      },
      {
        label: 'dashboard.farmMillAssignments',
        route: routes.FARM_MILL_ASSIGNMENTS,
        icon: <FarmMillAssignmentsIcon fontSize='large' />,
      },
      {
        label: 'dashboard.farmVisit',
        route: routes.ADMIN_FARM_VISIT,
        icon: <FarmVisitIcon fontSize='large' />,
      },
    ],
    []
  );

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      <h2>Dashboard</h2>
      {/*<Box*/}
      {/*  component='div'*/}
      {/*  sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 12 }}*/}
      {/*>*/}
      {/*  <img alt='amts-logo' src={amtsLogo} />*/}
      {/*</Box>*/}

      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginTop: 4,
          }}
        >
          <Grid columns={{ xs: 4, sm: 8, md: 12 }} container spacing={{ xs: 2, md: 3 }}>
            {links?.map((link) => (
              <Grid item key={`link_${link.route}_${link.label}`} md={4} sm={4} xs={2}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    key={t(link.label)}
                    onClick={() => navigate(link.route)}
                    sx={{
                      height: 160,
                      width: 240,
                      backgroundColor: '#fff !important',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                    variant='outlined'
                  >
                    {link.icon}
                    <Typography mt={2} sx={{ fontWeight: 'bold' }}>
                      {t(link.label)}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default AdminDashboardPage;
