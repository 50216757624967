/* eslint-disable max-len */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RationIcon from '@mui/icons-material/AssignmentOutlined';
import NutrientsIcon from '@mui/icons-material/EggOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useNavigate } from 'react-router-dom';

import { getAllFarms, setExactRecipe, setUserFarms } from '../../../actions/farmsActions';
import {
  getNutrientsById,
  getRationById,
  getTemplateList,
  resetRation,
  setExactFeed,
} from '../../../actions/rationsActions';
import LoadScreen from '../../../components/load-screen';
import { RationsTree, RationsTable, NutrientsTable, TemplateSelect } from '../../../components/rations';
import FeedList from '../../../components/rations/FeedList';
import NotesModal from '../../../components/rations/NotesModal';
import ResizeHandle from '../../../components/resize-handle';
import { SideMenu, BottomNavigation, SearchInput, Button } from '../../../components/shared';
import NoResults from '../../../components/shared/no-results';
import routes from '../../../constants/routes';
import { useLoading, useSettings } from '../../../hooks';
import { rationsSelector, farmsSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const tabs = {
  TREE: 'tree',
  TABLE: 'table',
  TEMPLATE: 'template',
};

const formatToRationObj = (farmObj) => {
  let cattleName, locationName, animalType;
  farmObj[0]?.locations?.forEach((loc) => {
    loc?.cattles?.forEach((cat) => {
      cat?.implementations?.forEach((impl) => {
        if (
          impl?.implemented_oadate === farmObj[0]?.last_implemented_oadate &&
          farmObj[0]?.last_implemented_by === impl?.implemented_by &&
          farmObj[0]?.ration_name === impl?.recipe
        ) {
          cattleName = cat.cattle_name;
          animalType = cat.animal_type;
          locationName = loc.location_name;
        }
      });
    });
  });

  return {
    Farm_ID: farmObj[0]?.farm_id,
    LocationName: locationName || farmObj[0]?.locations[0]?.location_name,
    CattleName: cattleName || farmObj[0]?.locations[0]?.cattles[0]?.cattle_name,
    RecipeName: farmObj[0]?.ration_name || farmObj[0]?.locations[0]?.cattles[0]?.implementations[0]?.recipe,
    OADate:
      farmObj[0]?.last_implemented_oadate ||
      farmObj[0]?.locations[0]?.cattles[0]?.implementations[0]?.implemented_oadate,
    implemented_oadate:
      farmObj[0]?.last_implemented_oadate ||
      farmObj[0]?.locations[0]?.cattles[0]?.implementations[0]?.implemented_oadate,
    farmName: farmObj[0]?.farm_name,
    animalType: animalType || farmObj[0]?.locations[0]?.cattles[0]?.animal_type,
    locName: locationName || farmObj[0]?.locations[0]?.location_name,
    cattleName: cattleName || farmObj[0]?.locations[0]?.cattles[0]?.cattle_name,
    farmId: farmObj[0]?.farm_id,
  };
};

const RationsPage = () => {
  const { t } = useTranslation();
  const { formatDate } = useSettings();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rations = useSelector(rationsSelector);
  const farms = useSelector(farmsSelector);

  const { templateList, ration, nutrients, templateListLoaded, info } = rations;
  const { pickedFarms, farmsList, exactFarm, isLoaded } = farms;

  const [currentTemplate, setCurrentTemplate] = useState(undefined);
  const [currentInfo, setCurrentInfo] = useState(undefined);
  // const [collapsed, setCollapse] = useState(!exactFarm.length);
  const [collapsed, setCollapse] = useState(true);
  const [currentPage, setCurrentPage] = useState(!exactFarm.length ? tabs.TREE : tabs.TABLE);
  const [fetching, setFetching] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [search, setSearch] = useState('');

  const { loading, startLoading, stopLoading } = useLoading(true);

  const onLoad = async () => {
    try {
      startLoading();
      await dispatch(getTemplateList());
      await dispatch(getAllFarms());
      if (exactFarm.length) {
        setCollapse(false);
        const farmObj = formatToRationObj(exactFarm);

        setCurrentInfo(farmObj);
        setFetching(true);
        await dispatch(getRationById(farmObj));
        await dispatch(getNutrientsById(farmObj));
        setFetching(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);

    return () => {
      dispatch(resetRation());
      dispatch(setExactRecipe([])).catch(console.error);
    };
  }, []);

  const handleRationClick = async (rec) => {
    try {
      const rationObj = {
        Farm_ID: rec.farmId,
        LocationName: rec.locName,
        CattleName: rec.cattleName,
        RecipeName: rec.recipe,
        OADate: rec.implemented_oadate,
      };
      setFetching(true);
      setCurrentInfo(rec);
      await dispatch(getRationById(rationObj));
      await dispatch(getNutrientsById(rationObj));
      setCollapse(false);
      setCurrentPage(tabs.TABLE);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const handleFeedClick = (feed, feedType) => {
    const feedObj = {
      feedName: feed,
      ...currentInfo,
    };

    dispatch(setExactFeed(feedObj));
    if (feedType?.toLowerCase()?.includes('composite')) {
      navigate(routes.MIXES);
    } else {
      navigate(routes.FEEDS);
    }
  };

  const handleTemplateChange = (e) => {
    setCurrentTemplate(templateList.find((el) => el.template_name === e.target.value) || undefined);
  };

  const onFarmsSelect = async (farms) => {
    dispatch(setUserFarms(farms));
  };

  const filteredNutrients = currentTemplate
    ? currentTemplate?.ration_outputs
        ?.filter((curT) => !!nutrients?.find((el) => el.label === curT.Output) && curT.Visible)
        ?.map((curT) => nutrients?.find((el) => el.label === curT.Output))
    : nutrients;

  const currentFarmsList = exactFarm.length > 0 ? exactFarm : pickedFarms.length > 0 ? pickedFarms : farmsList;
  const rationInfo = `${currentInfo?.farmName} - ${currentInfo?.locName} - ${currentInfo?.cattleName} - ${
    currentInfo?.implemented_oadate && formatDate(currentInfo?.implemented_oadate, true)
  }`;

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: {
      sm: '8px 16px 48px',
      xs: '8px 0 48px',
    },
  });

  const navigationTabs = [
    {
      value: tabs.TREE,
      label: t('rations.rationsList'),
      icon: <TreeIcon />,
    },
    {
      value: tabs.TABLE,
      label: t('rations.rationView'),
      icon: <RationIcon />,
      disabled: !currentInfo,
    },
    {
      value: tabs.TEMPLATE,
      label: t('rations.nutrients'),
      icon: <NutrientsIcon />,
      disabled: !currentInfo,
    },
  ];

  const list = useMemo(
    () => filteredNutrients?.filter((n) => n?.label?.toLowerCase()?.includes(search?.toLowerCase())),
    [filteredNutrients, search]
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        p: {
          lg: 0,
          md: 0,
          sm: 0,
          sx: 0,
        },
        position: 'relative',
      }}
    >
      {((loading && !templateListLoaded) || (mobileView && fetching)) && <LoadScreen />}
      {mobileView ? (
        <>
          <Box component='div' sx={getMobileStyles(tabs.TREE)}>
            <Box component='div' sx={{ pb: 2 }}>
              <RationsTree
                farmsList={farmsList}
                handleClick={handleRationClick}
                mobileView={mobileView}
                onFarmsSelect={onFarmsSelect}
                pickedFarms={pickedFarms}
              />
              {isLoaded && !farmsList?.length && <NoResults text={t('rations.noResults')} />}
            </Box>
          </Box>
          <Box component='div' sx={getMobileStyles(tabs.TABLE)}>
            {!loading && (
              <>
                <Typography
                  component='h6'
                  sx={{
                    margin: '8px 0',
                    fontWeight: 'bold',
                  }}
                  variant='h6'
                >
                  {t('rations.rationView')}
                  {info?.notes && (
                    <Button onClick={() => setShowNotesModal(true)} size='small' sx={{ ml: 1 }}>
                      {t('rations.notes')}
                    </Button>
                  )}
                </Typography>
                <Typography
                  component='span'
                  sx={{
                    margin: '8px 0',
                    fontWeight: 'bold',
                    color: ({ palette }) => palette?.primary?.greyDark,
                  }}
                >
                  {rationInfo}
                </Typography>
                <Box component='div' sx={{ margin: '16px 0' }}>
                  <RationsTable
                    data={ration}
                    handleFeedClick={handleFeedClick}
                    info={info}
                    isMobile={mobileView}
                    loading={fetching}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box component='div' sx={getMobileStyles(tabs.TEMPLATE)}>
            <Box
              component='div'
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              <TemplateSelect
                currentTemplate={currentTemplate}
                list={templateList}
                onChange={handleTemplateChange}
                size='small'
              />
              <SearchInput
                handleClear={() => setSearch('')}
                onChange={(e) => setSearch(e.target.value)}
                size='small'
                sx={{ width: '100%', mt: 2 }}
                value={search}
              />
            </Box>
            <NutrientsTable animalType={currentInfo?.animalType} data={list} mobileView={mobileView} />
          </Box>
          <BottomNavigation
            activeTab={currentPage}
            onChange={(event, newValue) => {
              if (currentInfo) {
                setCurrentPage(newValue);
              }
            }}
            tabs={navigationTabs}
          />
        </>
      ) : (
        <>
          {!!currentFarmsList?.length && (
            <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
              <RationsTree
                farmsList={farmsList}
                handleClick={handleRationClick}
                onFarmsSelect={onFarmsSelect}
                pickedFarms={pickedFarms}
              />
            </SideMenu>
          )}

          <Container maxWidth={false} sx={{ p: containerPadding }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mb: 1 }}>
              <h2 style={{ marginBottom: '0px' }}>{t('rations.title')}</h2>
              <TemplateSelect
                currentTemplate={currentTemplate}
                list={templateList}
                onChange={handleTemplateChange}
                size='small'
              />
            </Box>
            {isLoaded && !farmsList?.length && <NoResults text={t('rations.noResults')} />}
            {!ration?.length && !collapsed && (
              <Box
                component='div'
                sx={{
                  width: 'fit-content',
                  mt: 2,
                }}
              >
                <MuiAlert icon={<ArrowBackIcon />} severity='warning' variant='outlined'>
                  {t('rations.warningAlert')}
                </MuiAlert>
              </Box>
            )}
            <PanelGroup autoSaveId='rations-panel-group' direction='horizontal'>
              {currentFarmsList?.length > 0 && (
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Panel defaultSize={75} minSize={25}>
                    {ration?.length > 0 ? (
                      <Box component='div' sx={{ pr: 1 }}>
                        <Box component='div' sx={{ height: '54px' }}>
                          <Typography
                            component='div'
                            sx={{
                              margin: '8px 0',
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: ({ palette }) => palette?.primary?.greyDark,
                            }}
                          >
                            {rationInfo}
                            {info?.notes && (
                              <Button onClick={() => setShowNotesModal(true)} size='small' sx={{ ml: 1 }}>
                                {t('rations.notes')}
                              </Button>
                            )}
                          </Typography>
                        </Box>
                        <RationsTable data={ration} handleFeedClick={handleFeedClick} info={info} loading={fetching} />
                      </Box>
                    ) : (
                      <Box component='div' />
                    )}
                  </Panel>
                  {ration?.length > 0 && <ResizeHandle />}
                  <Panel defaultSize={25} minSize={25}>
                    {templateList && currentInfo && !loading && (
                      <Box component='div' sx={{ pl: 1 }}>
                        {!!ration?.length && (
                          <Box
                            component='div'
                            sx={{
                              mb: 2,
                              mt: 1,
                            }}
                          >
                            <SearchInput
                              handleClear={() => setSearch('')}
                              onChange={(e) => setSearch(e.target.value)}
                              size='small'
                              sx={{ width: '100%' }}
                              value={search}
                            />
                          </Box>
                        )}

                        <NutrientsTable animalType={currentInfo?.animalType} data={list} loading={fetching} />
                      </Box>
                    )}
                  </Panel>
                </Box>
              )}
            </PanelGroup>
          </Container>
        </>
      )}
      <NotesModal
        mobileView={mobileView}
        notes={info?.notes}
        onClose={() => setShowNotesModal(false)}
        open={showNotesModal}
        rationInfo={rationInfo}
      />
    </Container>
  );
};

export default RationsPage;
