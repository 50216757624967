/* eslint-disable max-len */
import FarmsIcon from '@mui/icons-material/Agriculture';
import ArticleIcon from '@mui/icons-material/Article';
import RationsIcon from '@mui/icons-material/Assignment';
import FarmMillAssignmentsIcon from '@mui/icons-material/AssignmentInd';
import BallotIcon from '@mui/icons-material/Ballot';
import ToolsIcon from '@mui/icons-material/Build';
import CalculateIcon from '@mui/icons-material/Calculate';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CompareIcon from '@mui/icons-material/Compare';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FarmVisitIcon from '@mui/icons-material/DirectionsWalk';
import ChangeProjectIcon from '@mui/icons-material/Home';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import AccessIcon from '@mui/icons-material/ManageAccounts';
import ActivityIcon from '@mui/icons-material/ManageHistory';
import FeedsIcon from '@mui/icons-material/Tornado';
import MixIcon from '@mui/icons-material/ViewList';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import amtsLogo from '../../assets/amts-logo.png';
import { userRoles } from '../../constants/roles';
import routes from '../../constants/routes';
import { SSO_URL } from '../../constants/urls';
import { userRolesSelector } from '../../reducers/selectors';
import { Accordion, AccordionDetails } from '../activity-log/styled';
import RestrictComponent from '../restrict-component';
import Button from '../shared/button';

import { Drawer, DrawerHeader } from './styled';

const { ADMIN, SUBSCRIBER } = userRoles;

export default function Sidebar({ drawerWidth, open, handleDrawerClose, theme }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const roles = useSelector(userRolesSelector);

  const [expanded, setExpanded] = React.useState(false);
  const [compareExpanded, setCompareExpanded] = React.useState(false);

  const adminSidebarItems = [
    { label: t('sidebar.amtsDashboard'), route: routes.DASHBOARD, icon: (color) => <DashboardIcon color={color} /> },
    { label: t('sidebar.farmAccess'), route: routes.FARM_ACCESS, icon: (color) => <AccessIcon color={color} /> },
    {
      label: t('sidebar.farmMillAssignments'),
      route: routes.FARM_MILL_ASSIGNMENTS,
      icon: (color) => <FarmMillAssignmentsIcon color={color} />,
    },
    { label: t('sidebar.activityLog'), route: routes.ACTIVITY_LOG, icon: (color) => <ActivityIcon color={color} /> },
    { label: t('sidebar.farmVisit'), route: routes.ADMIN_FARM_VISIT, icon: (color) => <FarmVisitIcon color={color} /> },
  ];

  const sidebarItems = [
    { label: t('sidebar.siloDashboard'), route: routes.HOME, icon: (color) => <DashboardIcon color={color} /> },
    { label: t('sidebar.farms'), route: routes.FARMS, icon: (color) => <FarmsIcon color={color} /> },
    { label: t('sidebar.rations'), route: routes.RATIONS, icon: (color) => <RationsIcon color={color} /> },
    { label: t('sidebar.mixes'), route: routes.MIXES, icon: (color) => <MixIcon color={color} /> },
    { label: t('sidebar.feeds'), route: routes.FEEDS, icon: (color) => <FeedsIcon color={color} /> },
  ];

  const mobileView = useMediaQuery((th) => th.breakpoints.down('md'));

  const renderList = (list) => (
    <List sx={{ pb: 0 }}>
      {list?.map((item) => (
        <ListItem
          button
          key={item.label}
          onClick={() => {
            navigate(item.route);
            if (mobileView) {
              handleDrawerClose();
            }
          }}
          sx={
            location?.pathname === item.route
              ? {
                  color: theme?.palette?.primary?.main,
                  background: '#f0f0f0 !important',
                  borderRight: `3px solid ${theme?.palette?.primary?.main}`,
                }
              : {}
          }
        >
          <ListItemIcon>{item.icon(location?.pathname === item.route ? 'primary' : '')}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
    </List>
  );

  const content = (
    <Box sx={{ flex: 1 }}>
      <RestrictComponent roles={[ADMIN]}>
        {roles?.length > 1 && (
          <Typography
            component='h6'
            sx={{
              fontSize: 12,
              mt: 2,
              ml: open ? 2 : 0,
              textAlign: open ? 'left' : 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: theme?.palette?.primary?.greyDark,
            }}
          >
            {open ? t(ADMIN.localeKey) : t(ADMIN.localeKey)[0]}
          </Typography>
        )}
        {renderList(adminSidebarItems)}
        <Box
          component='div'
          sx={{ width: '100%', borderBottom: `1px solid ${theme?.palette?.primary?.grey}`, mb: 1 }}
        />
      </RestrictComponent>

      <RestrictComponent roles={[SUBSCRIBER]}>
        {roles?.length > 1 && (
          <Typography
            component='h6'
            sx={{
              fontSize: 12,
              mt: 1,
              ml: open ? 2 : 0,
              textAlign: open ? 'left' : 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: theme?.palette?.primary?.greyDark,
            }}
          >
            {open ? t(SUBSCRIBER.localeKey) : t(SUBSCRIBER.localeKey)[0]}
          </Typography>
        )}
        {renderList(sidebarItems)}
        <Accordion
          expanded={compareExpanded}
          sx={{
            border: 'none',
            background: () => (mobileView ? theme?.palette?.primary?.greyLight : '#ffffff'),
          }}
        >
          <ListItem
            button
            onClick={() => setCompareExpanded(!compareExpanded)}
            secondaryAction={
              open && (
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme?.palette?.primary?.greyDark,
                  }}
                >
                  {compareExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </Box>
              )
            }
          >
            <ListItemIcon>
              <CompareIcon />
            </ListItemIcon>
            <ListItemText primary={t('sidebar.comparisons')} />
          </ListItem>
          <AccordionDetails sx={{ border: 'none', padding: 0 }}>
            <ListItem
              button
              onClick={() => {
                navigate(routes.COMPARE_RATIONS);
                if (mobileView) {
                  handleDrawerClose();
                }
              }}
              secondaryAction={
                open && (
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: location?.pathname?.includes(routes.COMPARE_RATIONS)
                        ? theme?.palette?.primary?.main
                        : '#cccccc',
                    }}
                  >
                    <RationsIcon />
                  </Box>
                )
              }
              sx={
                location?.pathname?.includes(routes.COMPARE_RATIONS)
                  ? {
                      color: theme?.palette?.primary?.main,
                      background: '#f0f0f0 !important',
                      borderRight: `3px solid ${theme?.palette?.primary?.main}`,
                      '.MuiListItemSecondaryAction-root': {
                        right: location?.pathname?.includes(routes.COMPARE_RATIONS) ? '13px' : '16px',
                      },
                    }
                  : {}
              }
            >
              <ListItemIcon>
                {!open && (
                  <RationsIcon
                    sx={{
                      color: location?.pathname?.includes(routes.COMPARE_RATIONS)
                        ? theme?.palette?.primary?.main
                        : '#cccccc',
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary={t('sidebar.compareRations')} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate(routes.COMPARE_FEEDS);
                if (mobileView) {
                  handleDrawerClose();
                }
              }}
              secondaryAction={
                open && (
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: location?.pathname?.includes(routes.COMPARE_FEEDS)
                        ? theme?.palette?.primary?.main
                        : '#cccccc',
                    }}
                  >
                    <FeedsIcon />
                  </Box>
                )
              }
              sx={
                location?.pathname?.includes('particle-size')
                  ? {
                      color: theme?.palette?.primary?.main,
                      background: '#f0f0f0 !important',
                      borderRight: `3px solid ${theme?.palette?.primary?.main}`,
                      '.MuiListItemSecondaryAction-root': {
                        right: location?.pathname?.includes(routes.COMPARE_FEEDS) ? '13px' : '16px',
                      },
                    }
                  : {}
              }
            >
              <ListItemIcon>
                {!open && (
                  <FeedsIcon
                    sx={{
                      color: location?.pathname?.includes(routes.COMPARE_FEEDS)
                        ? theme?.palette?.primary?.main
                        : '#cccccc',
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary={t('sidebar.compareFeeds')} />
            </ListItem>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded}
          sx={{
            border: 'none',
            background: () => (mobileView ? theme?.palette?.primary?.greyLight : '#ffffff'),
          }}
        >
          <ListItem
            button
            onClick={() => setExpanded(!expanded)}
            secondaryAction={
              open && (
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme?.palette?.primary?.greyDark,
                  }}
                >
                  {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </Box>
              )
            }
          >
            <ListItemIcon>
              <ToolsIcon />
            </ListItemIcon>
            <ListItemText primary={t('sidebar.tools')} />
          </ListItem>
          <AccordionDetails sx={{ border: 'none', padding: 0 }}>
            <ListItem
              button
              onClick={() => {
                navigate(routes.BCS);
                if (mobileView) {
                  handleDrawerClose();
                }
              }}
              secondaryAction={
                open && (
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: location?.pathname?.includes(routes.BCS) ? theme?.palette?.primary?.main : '#cccccc',
                    }}
                  >
                    <BallotIcon />
                  </Box>
                )
              }
              sx={
                location?.pathname?.includes(routes.BCS)
                  ? {
                      color: theme?.palette?.primary?.main,
                      background: '#f0f0f0 !important',
                      borderRight: `3px solid ${theme?.palette?.primary?.main}`,
                      '.MuiListItemSecondaryAction-root': {
                        right: location?.pathname?.includes(routes.BCS) ? '13px' : '16px',
                      },
                    }
                  : {}
              }
            >
              <ListItemIcon>
                {!open && (
                  <BallotIcon
                    sx={{
                      color: location?.pathname?.includes(routes.BCS) ? theme?.palette?.primary?.main : '#cccccc',
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary={t('sidebar.bodyConditionScore')} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate(routes.PSC);
                if (mobileView) {
                  handleDrawerClose();
                }
              }}
              secondaryAction={
                open && (
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: location?.pathname?.includes(routes.PSC) ? theme?.palette?.primary?.main : '#cccccc',
                    }}
                  >
                    <CalculateIcon />
                  </Box>
                )
              }
              sx={
                location?.pathname?.includes('particle-size')
                  ? {
                      color: theme?.palette?.primary?.main,
                      background: '#f0f0f0 !important',
                      borderRight: `3px solid ${theme?.palette?.primary?.main}`,
                      '.MuiListItemSecondaryAction-root': {
                        right: location?.pathname?.includes(routes.PSC) ? '13px' : '16px',
                      },
                    }
                  : {}
              }
            >
              <ListItemIcon>
                {!open && (
                  <CalculateIcon
                    sx={{
                      color: location?.pathname?.includes(routes.PSC) ? theme?.palette?.primary?.main : '#cccccc',
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary={t('sidebar.particleSizeCalculator')} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate(routes.FARM_VISIT);
                if (mobileView) {
                  handleDrawerClose();
                }
              }}
              secondaryAction={
                open && (
                  <Box
                    component='div'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color:
                        location?.pathname?.includes(routes.FARM_VISIT) &&
                        !location?.pathname?.includes(routes.ADMIN_FARM_VISIT)
                          ? theme?.palette?.primary?.main
                          : '#cccccc',
                    }}
                  >
                    <ArticleIcon />
                  </Box>
                )
              }
              sx={
                location?.pathname?.includes(routes.FARM_VISIT) &&
                !location?.pathname?.includes(routes.ADMIN_FARM_VISIT)
                  ? {
                      color: theme?.palette?.primary?.main,
                      background: '#f0f0f0 !important',
                      borderRight: `3px solid ${theme?.palette?.primary?.main}`,
                      '.MuiListItemSecondaryAction-root': {
                        right: location?.pathname?.includes(routes.FARM_VISIT) ? '13px' : '16px',
                      },
                    }
                  : {}
              }
            >
              <ListItemIcon>
                {!open && (
                  <ArticleIcon
                    sx={{
                      color:
                        location?.pathname?.includes(routes.FARM_VISIT) &&
                        !location?.pathname?.includes(routes.ADMIN_FARM_VISIT)
                          ? theme?.palette?.primary?.main
                          : '#cccccc',
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary={t('sidebar.farmVisit')} />
            </ListItem>
          </AccordionDetails>
        </Accordion>
      </RestrictComponent>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor='left'
        open={open}
        sx={{ display: { xs: 'none', md: 'inherit' } }}
        variant='permanent'
        width={drawerWidth}
      >
        <DrawerHeader>
          <img alt='amts-logo' onClick={() => navigate(routes.HOME)} src={amtsLogo} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {open ? (
          <Box sx={{ width: '100%', px: 2, py: 1 }}>
            <Button component='a' fullWidth href={SSO_URL} rel='noopener noreferrer' startIcon={<ChangeProjectIcon />}>
              {t('changeProgram')}
            </Button>
          </Box>
        ) : (
          <a href={SSO_URL} rel='noopener noreferrer' style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton color='primary'>
              <ChangeProjectIcon />
            </IconButton>
          </a>
        )}
        {content}
      </Drawer>

      <MuiDrawer
        ModalProps={{
          keepMounted: true,
        }}
        onClose={handleDrawerClose}
        open={open}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 300,
            maxWidth: '75%',
            paddingTop: '60px',
            backgroundColor: theme.palette.primary?.greyLight,
          },
        }}
        variant={open && window?.screen?.width >= theme?.breakpoints?.values?.md ? 'permanent' : 'temporary'}
      >
        {open ? (
          <Box sx={{ width: '100%', px: 2, py: 1 }}>
            <Button component='a' fullWidth href={SSO_URL} rel='noopener noreferrer' startIcon={<ChangeProjectIcon />}>
              {t('changeProgram')}
            </Button>
          </Box>
        ) : (
          <a href={SSO_URL} rel='noopener noreferrer' style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton color='primary'>
              <ChangeProjectIcon />
            </IconButton>
          </a>
        )}
        {content}
      </MuiDrawer>
    </>
  );
}
