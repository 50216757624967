import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Select } from '../shared';

// import AddExternalUserModal from './AddExternalUserModal';

const MillAssignmentModal = (props) => {
  const { open, onClose, onSubmit, data, mills, loading, externalUserLoading, onAddExternalUser } = props;

  const { t } = useTranslation();

  const [selectedMill, setSelectedMill] = useState(null);
  const [removedInternalUsers, setRemovedInternalUsers] = useState([]);
  const [removedExternalUsers, setRemovedExternalUsers] = useState([]);

  const [externalModalOpen, toggleExternalModal] = useState(false);

  useEffect(() => {
    if (open && data?.mill) {
      setSelectedMill(data.mill?.mill_id);
    }
  }, [open, data]);

  const handleInternalChange = (email) => {
    if (removedInternalUsers?.includes(email)) {
      setRemovedInternalUsers([...removedInternalUsers].filter((u) => u !== email));
    } else {
      setRemovedInternalUsers([...removedInternalUsers, email]);
    }
  };

  const handleExternalChange = (email) => {
    if (removedExternalUsers?.includes(email)) {
      setRemovedExternalUsers([...removedExternalUsers].filter((u) => u !== email));
    } else {
      setRemovedExternalUsers([...removedExternalUsers, email]);
    }
  };

  const handleAddUser = async (date, userEmail) => {
    await onAddExternalUser(date, userEmail);
    toggleExternalModal(false);
  };

  return (
    <Modal
      closeText={t('cancel')}
      maxWidth='md'
      onClose={onClose}
      onSubmit={() => onSubmit(selectedMill, [...removedInternalUsers, ...removedExternalUsers])}
      open={open}
      submitDisabled={loading}
      submitLoading={loading}
      submitText={t('saveChanges')}
      sxBody={{ padding: '24px 16px' }}
      title={t('farmMillAssignments.millAssignmentModalTitle', { farmName: data?.farm_name || '' })}
    >
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>{t('farmMillAssignments.millAssociation')}</Typography>
          <Box sx={{ width: '50%' }}>
            <Select
              id='existing-mill'
              // label={t('farmMillAssignments.millAssociation')}
              name='existing-mill'
              onChange={(e) => setSelectedMill(e.target.value)}
              options={mills?.map((m) => ({ key: m.mill_id, value: m.mill_id, label: m.name }))}
              value={selectedMill}
            />
          </Box>
        </Box>
        {/*<Box>*/}
        {/*  <Button onClick={() => toggleExternalModal(true)} sx={{ margin: '0 0 0 16px', minWidth: '180px' }}>*/}
        {/*    {t('farmsAccess.addExtUser')}*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Box>
      <Grid component='div' container spacing={2}>
        <Grid component='div' item md={6} sm={12} xs={12}>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>{t('farmMillAssignments.internalUserAccess')}</Typography>
          {data.internal_users?.map((email) => (
            <Box key={email}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!removedInternalUsers?.includes(email)}
                    onChange={(e) => handleInternalChange(email)}
                    size='small'
                  />
                }
                label={email}
              />
            </Box>
          ))}
        </Grid>
        <Grid component='div' item md={6} sm={12} xs={12}>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>{t('farmMillAssignments.externalUserAccess')}</Typography>
          {data.external_users?.map((email) => (
            <Box key={email}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!removedExternalUsers?.includes(email)}
                    onChange={(e) => handleExternalChange(email)}
                    size='small'
                  />
                }
                label={email}
              />
            </Box>
          ))}
        </Grid>
      </Grid>

      {/*<AddExternalUserModal*/}
      {/*  loading={externalUserLoading}*/}
      {/*  onClose={() => toggleExternalModal(false)}*/}
      {/*  onSubmit={handleAddUser}*/}
      {/*  open={externalModalOpen}*/}
      {/*/>*/}
    </Modal>
  );
};

export default MillAssignmentModal;
