import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { nutrientLabels } from '../../constants';
import { useSettings } from '../../hooks';
import { Table, Skeleton } from '../shared';

const OutputsTable = (props) => {
  const { data, selected, onSelect, nutrients = [], loading, isMobile } = props;

  const { t } = useTranslation();
  const { formatLabel, formatWeight, formatDate } = useSettings();

  const getColumns = () => {
    const arr = [
      {
        title: t('nutrient'),
        field: 'label',
        sort: true,
        headerStyles: { paddingInlineStart: '48px', minWidth: '200px', zIndex: 1 },
        formatter: (value) => (
          <Box component='div' sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Checkbox
              checked={!!selected?.includes(value)}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={() => onSelect(value)}
              sx={{ padding: 0, marginInlineEnd: 1, zIndex: 0 }}
              value={value}
            />
            <Box component='span' sx={{ verticalAlign: 'middle' }}>
              {formatLabel(value)}
            </Box>
          </Box>
        ),
        exportFormatter: (value) => formatLabel(value),
      },
    ];

    data.forEach((el, idx) => {
      arr.push({
        title: el.FeedName,
        field: idx,
        sort: true,
        formatter: (value, row) => {
          const formattedLabel = formatLabel(row.label);
          return formatWeight(value, formattedLabel, formattedLabel, undefined, false, row.label);
        },
        headerFormatter: () => (
          <Box component='div' sx={{ minWidth: '220px' }}>
            <Box component='div'>{el.farmName}</Box>
            <Box component='div'>{el.FeedName}</Box>
            <Box component='div'>{formatDate(el.OADate, true)}</Box>
          </Box>
        ),
      });
    });

    return arr;
  };

  const getRows = () => {
    const arr = [];
    nutrients.forEach((key) => {
      const field = Object.keys(nutrientLabels)?.find((k) => nutrientLabels[k] === key);
      const row = { label: key };
      data.forEach((ration, idx) => {
        row[idx] = ration?.nutrients ? ration?.nutrients[field] || 0 : 0;
      });
      arr.push(row);
    });

    return arr;
  };

  if (loading) {
    return <Skeleton rows={8} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  const columns = getColumns();
  const rows = getRows();

  return (
    <Table
      columns={columns}
      keyField='key'
      pagination
      rows={rows}
      stickyColumn
      stickyHeader
      sx={{ maxHeight: { md: 'calc(100vh - 340px)', xs: 'calc(100vh - 140px)' } }}
      toolbar
    />
  );
};

export default OutputsTable;
